import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FileUploader } from 'ng2-file-upload';
import { SafeUrl } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { NbTokenService, NbAuthService } from '@nebular/auth';

@Component({
  selector: 'outdated-token-modal',
  templateUrl: './outdated-token-modal.component.html',
  styleUrls: ['./outdated-token-modal.component.scss']
})
export class OutdatedTokenModalComponent implements OnInit {

  constructor(private router: Router, private tokenService: NbTokenService, private authService: NbAuthService) { }
  
  ngOnInit() {
      
  }

  logOut() {
    this.tokenService.clear().subscribe( res => {
      console.log(res);
      this.router.navigate(['/']);
    });
  }

}