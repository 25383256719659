import { NgModule } from '@angular/core';
import { NbMenuModule, NbCardModule, NbButtonModule } from '@nebular/theme';

import { ThemeModule } from '../@theme/theme.module';
import { OutdatedTokenModalComponent } from './outdated-token-modal/outdated-token-modal.component';


@NgModule({
  imports: [
    NbCardModule,
    ThemeModule,
    NbMenuModule,
    NbButtonModule
  ],
  declarations: [
    OutdatedTokenModalComponent
  ],
  entryComponents: [OutdatedTokenModalComponent]
})
export class SharedModule {
}
