import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UsersService} from '../../../pages/user/user.service';
import { User } from '../../../pages/user/user';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { Organisation } from '../../../pages/organisation/organisation';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  title: String = "Automatismo de Registros Contables";

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: User;
  organisation: Organisation;
  logoUrl: String = "";
  salesModuleActivated: boolean = false;

  userMenu = [ { title: 'Log out' , link: '/auth/logout'} ];

  constructor(private sidebarService: NbSidebarService, private menuService: NbMenuService, private themeService: NbThemeService,
              private userService: UsersService, private breakpointService: NbMediaBreakpointsService, private authService: NbAuthService) {


  }

  ngOnInit() {

    this.userService.getCurrentUser().subscribe(user => {
      this.user = user;
      this.organisation = user.organisation;
      this.logoUrl = this.organisation.logo;
      this.title = this.organisation.name;
      this.salesModuleActivated = this.organisation['sales_module'];
      this.userMenu = [ { title: 'Perfil', link: '/pages/users/edit/'+this.user.id}, { title: 'Salir' , link: '/auth/logout'} ];
    },
    error => {
      console.log("EN HEADER");
      window.location.reload();
    });

    const { xl } = this.breakpointService.getBreakpointsMap();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
