import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
        <p class="created-by">ARC: Soluciones Empresariales | Marca Registrada</p>
  `,
})
export class FooterComponent {
}
