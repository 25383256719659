import { environment } from '../../../environments/environment';
import { Organisation } from '../organisation/organisation';
import { Validators } from '@angular/forms';

export const USERS_BASE_URL = environment.apiDir + 'users/';

export class User {

  public static ADMIN = "ADMIN";
  public static OPERARIO = "OPERARIO";

  role_id: string = '';
  id: Number = 0;
  name: String = '';
  email: String = '';
  user_type: String = '';
  organisation_id: Number = 0;
  organisation: Organisation = new Organisation();
  password: String = '';
  password_confirmation: String = '';

  constructor(values: Object = {}) {
      Object.assign(this, values);
  }

  public isAdmin(): boolean{
    return this.user_type == User.ADMIN;
  }
}
