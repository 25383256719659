import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: NbAuthService, private router: Router, private http: HttpClient) {
  }

  canActivate() {
    // this.authService.isAuthenticated().subscribe( token => {debugger;});
    // this.authService.getToken().subscribe( token => {debugger;});
    return this.validSession()
                          .pipe(
                            tap(authenticated => {
                              if (!authenticated) {
                                this.router.navigate(['auth/login']);
                              }
                            }),
                          );
  }

  validSession(): Observable<boolean>{
    return this.http.get<boolean>(environment.apiDir + 'session/valid_session');
  }


}