import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRoute, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { User } from './user';
import { ɵINTERNAL_BROWSER_DYNAMIC_PLATFORM_PROVIDERS } from '@angular/platform-browser-dynamic';

@Injectable()
export class AdminGuard implements CanActivate {

    constructor(private _router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)  {
        let ret = false;
        let _url = state.url.split('/');
        let user = JSON.parse(localStorage.getItem('current_user'));
        if(user['user_type'] == User.ADMIN){
            ret = true;
        }else if (_url.includes('edit') && _url[_url.length - 1] == user.id){
            ret = true;
        }else{
            this._router.navigate(['/']);
        }
        return ret;
    }



}