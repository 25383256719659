import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

import { User, USERS_BASE_URL } from './user';

@Injectable()
export class UsersService {

  constructor(protected http: HttpClient) { }

  public getAll(): Observable<User[]> {

    let Params = new HttpParams();

    return this.http.get<User[]>(USERS_BASE_URL, { params: Params });
  }

  public getUser(id: Number): Observable<User>{
    return this.http.get<User>(USERS_BASE_URL + id);
  }

  public validSession(): Observable<boolean>{
    return this.http.get<boolean>(environment.apiDir + 'session/valid_session');
  }

  public getCurrentUser(): Observable<User> {
    return this.http.get<User>(environment.apiDir + 'session/get_current_user');
  }

  public create(user: User): Observable<User> {
    return this.http.post<User>(USERS_BASE_URL, user);
  }

  public update(user: User): Observable<User> {
    return this.http.put<User>(USERS_BASE_URL + user.id, user);
  }

  public delete(user: User): Observable<Object> {
    return this.http.delete<Object>(USERS_BASE_URL + user.id);
  }
}
