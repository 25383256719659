import { environment } from '../../../environments/environment';

export const ORGANISATIONS_BASE_URL = environment.apiDir + 'organisations/';

export class Organisation {
  id: Number = 0;
  name: String = '';
  legal_entity_name: String = '';
  logo: String = '';
  rut: String = '';
  contact_name: String = '';
  contact_phone: String = '';
  email: String = '';
  service_end: String = '';
  service_start: String = '';
  client_max: Number = 0;
  user_max: Number = 0;
  payment_type: String = '';
  allow_dgi_import: boolean = false;
  sales_module:boolean = false;

  constructor(values: Object = {}) {
      Object.assign(this, values);
  }
}
